import React from 'react';
import Link from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/seo.js"
const AboutPage = () => {
    return ( 

            <Layout>
                <SEO title="Anonycoder About Page"/>
                <Link to="/contact">Reach out</Link>
                </Layout>

     );
}
 
export default AboutPage;